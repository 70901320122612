import { Component } from '@angular/core';
import { TokenStorageService } from './_services/token-storage.service';

@Component({
	selector: 'body',
	template: '<router-outlet></router-outlet>',
})
export class AppComponent {
	title = 'Blockchainmind Admin';

	constructor(private token: TokenStorageService) { }

	logout(): void {
		this.token.signOut();
		window.location.reload();
	}
}
