import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadingStrategy, PreloadAllModules } from '@angular/router';

import { LayoutsComponent } from './layouts/layouts.component';
import { LoginComponent } from './views/login/login.component';

import {
  AuthenticatedGuardService as AuthenticatedGuard
} from './_guards/authenticated-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutsComponent,
    // pathMatch: 'full',
    children: [{
      path: '',
      loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule)
    }]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthenticatedGuard]
  },

  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
