import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    email: null,
    password: null
  };

  loginType: any = 'email';

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  isRegistered: any = false;
  isVerifyFailed: any = false;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().role;
    }
    this.isRegistered = this.ActivatedRoute.snapshot.paramMap.get('isRegistered');
  }

  onSubmit(): any {
    const { email, password } = this.form;
    this.isLoginFailed = false;
    this.isVerifyFailed = false;
    // this.form.password = this.form.otp;
    this.authService.adminLogin(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().role;
        this.reloadPage(this.roles);
      },
      err => {
        this.errorMessage = err.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage(role: any) {
    location.replace('/profile');
  }
}
