<div class="container-scroller">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <div class="content-wrapper min-vh-100">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
