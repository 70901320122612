import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
	selector: 'app-layouts',
	templateUrl: './layouts.component.html',
	styleUrls: ['./layouts.component.css']
})
export class LayoutsComponent implements OnInit {
	loggedRole: any;

	constructor(private TokenStorageService: TokenStorageService) { }

	ngOnInit(): void {
		this.loggedRole = this.TokenStorageService.getRole();
	}

}
