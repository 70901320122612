import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../_services/auth.service';
@Injectable({
	providedIn: 'root'
})
export class AuthenticatedGuardService implements CanActivate {
	constructor(public auth: AuthService, public router: Router) { }
	canActivate(): boolean {
		if (this.auth.isAuthenticated()) {
			this.router.navigate(['']);
			return true;
		}
		return true;
	}
}