import { Component, OnInit } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthService } from '../../_services/auth.service'
import { TokenStorageService } from '../../_services/token-storage.service';
declare var $: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	isLoggedIn: boolean = true;
	currentUser: any;
	constructor(private authService: AuthService, private token: TokenStorageService, @Inject(DOCUMENT) private document: any, private router: Router, private activatedRoute: ActivatedRoute) { }

	ngOnInit(): void {
		this.isLoggedIn = this.authService.isAuthenticated();
		this.currentUser = this.token.getUser();
		let sidebarToggle: any = document.querySelectorAll('[data-toggle="sidebar"]')
		sidebarToggle = Array.prototype.slice.call(sidebarToggle)

		sidebarToggle.forEach(function(toggle: any) {
			toggle.addEventListener('click', function(e: any) {
				var selector = e.currentTarget.getAttribute('data-target') || '#default-drawer'
				var drawer = document.querySelector(selector)
				if (drawer) {
					drawer.mdkDrawer.toggle()
				}
			})
		})

		let drawers: any = document.querySelectorAll('.mdk-drawer')
		drawers = Array.prototype.slice.call(drawers)
		drawers.forEach((drawer: any) => {
			drawer.addEventListener('mdk-drawer-change', (e: any) => {
				if (!e.target.mdkDrawer) {
					return
				}
				let body: any = document.querySelector('body');
				body.classList[e.target.mdkDrawer.opened ? 'add' : 'remove']('has-drawer-opened')
				let button = document.querySelector('[data-target="#' + e.target.id + '"]')
				if (button) {
					button.classList[e.target.mdkDrawer.opened ? 'add' : 'remove']('active')
				}
			})
		})
		$('.sidebar .collapse').on('show.bs.collapse', function(e) {
			$('.sidebar .collapse').closest('.sidebar').find('.open').find('.collapse').collapse('hide');
			$('.sidebar .collapse').closest('li').addClass('open');
		});

		$('.sidebar .collapse').on('hidden.bs.collapse', function(e) {
			$('.sidebar .collapse').closest('li').removeClass('open');
		});

		// SIDEBAR COLLAPSE MENUS

	}

	logout(): void {
		this.token.signOut();
		window.location.reload();
	}

	searchCourse(data) {
		if (data.q != '') {
			data.q.replace(' ', '+');
			const params = new HttpParams()
				.set('q', data.q)
			this.router.navigate(['/courses'], { relativeTo: this.activatedRoute, queryParams: data, queryParamsHandling: 'merge', });
		}
	}



}
