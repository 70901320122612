import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormArray, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentServices } from '../components.service';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SideBarComponent implements OnInit {
	
	constructor(	private _fb: FormBuilder,private sideBarService : ComponentServices,private router : Router
		) { 
	
	}

	ngOnInit(): void {
	}
	
}
