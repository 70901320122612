<nav class="sidebar sidebar-offcanvas sidebar-icon-only" id="sidebar">
  <ul class="nav">
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/news-blogs">
        <i class="mdi mdi-book-multiple menu-icon"></i>
        <span class="menu-title">News & Blogs</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/jobs">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Jobs</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/ourservices">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Our Services</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/ourclients">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Our Clients</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/award-recognition">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Awards and Recognition</span>
      </a>
    </li>
     <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/ourteam">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Our Team</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/testimonials">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Testimonials</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" routerLink="/our-story">
        <i class="mdi mdi-clipboard menu-icon"></i>
        <span class="menu-title">Our Stories</span>
      </a>
    </li>
  </ul>
</nav>
