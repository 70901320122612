<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
      <div class="row flex-grow">
        <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="auth-form-transparent text-left p-3">
            
            <h4>Welcome back to Blockchainmind!</h4>
            <form class="pt-3" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate autocomplete="off">
              <div class="form-group">
                <label for="email">Username</label>
                <div class="input-group">
                  <div class="input-group-prepend bg-transparent">
                    <span class="input-group-text bg-transparent border-right-0">
                      <i class="mdi mdi-account-outline text-primary"></i>
                    </span>
                  </div>
                  <input type="email" class="form-control form-control-lg border-left-0" id="email" placeholder="Username" name="email" [(ngModel)]="form.email" required #email="ngModel" autocomplete="off">
                </div>
                <label class="error mt-2 text-danger" *ngIf="email.errors && f.submitted">
                  Username is required!
                </label>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-group">
                  <div class="input-group-prepend bg-transparent">
                    <span class="input-group-text bg-transparent border-right-0">
                      <i class="mdi mdi-lock-outline text-primary"></i>
                    </span>
                  </div>
                  <input type="password" class="form-control form-control-lg border-left-0" id="password" placeholder="Password" name="password" [(ngModel)]="form.password" required #password="ngModel" autocomplete="off">
                </div>
                <label class="error mt-2 text-danger" *ngIf="password.errors && f.submitted">
                  <div *ngIf="password.errors.required">Password is required</div>
                </label>
              </div>
              <div class="my-3">
                <label class="error mt-1 mb-1 text-danger" *ngIf="isLoginFailed">{{errorMessage}}</label>
                <button type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">LOGIN </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6 login-half-bg d-flex flex-row">
          <p class="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2020 All rights reserved.</p>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
